'use client';

import { getImageProps } from 'next/image';
import { Container, Grid2, Typography, Box } from '@mui/material';
import { SectionTitleTriangle } from '../_components/SectionTitleTriangle';
import { ReactComponent as IconImpact1 } from './assets/icon-impact-1.svg';
import { ReactComponent as IconImpact2 } from './assets/icon-impact-2.svg';
import { ReactComponent as IconImpact3 } from './assets/icon-impact-3.svg';
import impactBgMobile from './assets/img-impact-bg-mobile.png';
import impactBg from './assets/img-impact-bg.png';
const cellsData = [{
  icon: <IconImpact1 style={{
    marginTop: 14
  }} />,
  titleNumber: 11,
  titleUnit: 'x',
  description: 'Increase in product visibility on Google with optimized SEO—driving more traffic to your site.'
}, {
  icon: <IconImpact2 style={{
    marginTop: 17
  }} />,
  titleNumber: 40,
  titleUnit: 'x',
  description: 'Higher customer order conversions compared to standard websites.'
}, {
  icon: <IconImpact3 style={{
    marginTop: 20
  }} />,
  titleNumber: 80,
  titleUnit: '%',
  description: 'Reduction in operational costs with streamlined automation.'
}];
type CellProps = {
  icon: React.ReactNode;
  titleNumber: number;
  titleUnit: string;
  description: string;
};
const Cell = ({
  icon,
  titleNumber,
  titleUnit,
  description
}: CellProps) => {
  return <Grid2 size={{
    xs: 12,
    md: 4
  }} sx={{
    display: 'flex'
  }} data-sentry-element="Grid2" data-sentry-component="Cell" data-sentry-source-file="QuotebeamImpactSection.tsx">
      <Box sx={{
      minWidth: 100
    }} data-sentry-element="Box" data-sentry-source-file="QuotebeamImpactSection.tsx">{icon}</Box>
      <Box data-sentry-element="Box" data-sentry-source-file="QuotebeamImpactSection.tsx">
        <Typography variant="h1" component="h4" sx={{
        color: 'common.white'
      }} data-sentry-element="Typography" data-sentry-source-file="QuotebeamImpactSection.tsx">
          {titleNumber}
          {titleUnit}
        </Typography>
        <Typography variant="paragraph" sx={{
        color: 'common.white',
        pr: [0, 5],
        mt: -1,
        minHeight: '4lh'
      }} data-sentry-element="Typography" data-sentry-source-file="QuotebeamImpactSection.tsx">
          {description}
        </Typography>
      </Box>
    </Grid2>;
};
export const QuotebeamImpactSection = () => {
  const {
    props: {
      src: srcDesktop
    }
  } = getImageProps({
    src: impactBg.src,
    fill: true,
    alt: 'bg'
  });
  const {
    props: {
      src: srcMobile
    }
  } = getImageProps({
    src: impactBgMobile.src,
    fill: true,
    alt: 'bg'
  });
  return <Box sx={{
    backgroundColor: '#02091D',
    backgroundImage: {
      xs: `url(${srcMobile})`,
      md: `url(${srcDesktop})`
    },
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat'
  }} data-sentry-element="Box" data-sentry-component="QuotebeamImpactSection" data-sentry-source-file="QuotebeamImpactSection.tsx">
      <Container sx={{
      pt: [4.5, 7],
      pb: [8, 15.25]
    }} data-sentry-element="Container" data-sentry-source-file="QuotebeamImpactSection.tsx">
        <Grid2 container columnSpacing={3} sx={{
        pb: [4.5, 7]
      }} data-sentry-element="Grid2" data-sentry-source-file="QuotebeamImpactSection.tsx">
          <Grid2 size={{
          xs: 12,
          md: 8
        }} offset={{
          md: 4
        }} data-sentry-element="Grid2" data-sentry-source-file="QuotebeamImpactSection.tsx">
            <Typography variant="h3" sx={theme => ({
            bgcolor: 'secondary.dark',
            color: 'secondary.contrastText',
            py: 1.25,
            // my: [4, 4, 11],
            position: 'relative',
            [theme.breakpoints.down('lg')]: {
              fontSize: 18,
              textAlign: 'center',
              py: 0.5,
              mb: 0,
              boxShadow: theme => `24px 0 0 ${theme.palette.secondary.dark},-24px 0 0 ${theme.palette.secondary.dark}`
            }
          })} data-sentry-element="Typography" data-sentry-source-file="QuotebeamImpactSection.tsx">
              <SectionTitleTriangle data-sentry-element="SectionTitleTriangle" data-sentry-source-file="QuotebeamImpactSection.tsx" />
              What&apos;s the Quotebeam Impact?
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 container columnSpacing={3} rowSpacing={{
        xs: 3,
        sm: 0
      }} data-sentry-element="Grid2" data-sentry-source-file="QuotebeamImpactSection.tsx">
          {cellsData.map(cellData => <Cell key={cellData.description} {...cellData} />)}
        </Grid2>
      </Container>
    </Box>;
};