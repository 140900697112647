'use client';

import { Typography } from '@mui/material';
import { SectionTitleTriangle } from '../_components/SectionTitleTriangle';
export const SolutionSectionsTitle = () => {
  return <Typography variant="h2" sx={theme => ({
    bgcolor: 'secondary.dark',
    color: 'secondary.contrastText',
    py: 1.25,
    my: [4, 4, 11],
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      textAlign: 'center',
      py: 0.5,
      mb: 0,
      boxShadow: theme => `24px 0 0 ${theme.palette.secondary.dark},-24px 0 0 ${theme.palette.secondary.dark}`
    }
  })} data-sentry-element="Typography" data-sentry-component="SolutionSectionsTitle" data-sentry-source-file="SolutionSectionsTitle.tsx">
      <SectionTitleTriangle data-sentry-element="SectionTitleTriangle" data-sentry-source-file="SolutionSectionsTitle.tsx" />
      Discover Key Features of Quotebeam
    </Typography>;
};