'use client';

/* eslint-disable no-nested-ternary */
import { useState, FC, SVGProps } from 'react';
import { Stack, Typography, Card, CardActionArea, CardActionAreaProps, useTheme, Collapse, Box } from '@mui/material';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { slidesData } from '@/app/(index)/_components/UmbrellaIntro/umbrellaIntro.data';
import { BaseCarousel } from '@/app/_components/BaseCarousel/BaseCarousel';
import { UmbrellaCarouselArrow } from './UmbrellaCarouselArrow';
type SlideProps = {
  title: string;
  href: string;
  description: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  size: 'small' | 'default' | 'large';
  onMouseEnter: CardActionAreaProps['onMouseEnter'];
  onMouseLeave: CardActionAreaProps['onMouseLeave'];
};
const Slide = ({
  title,
  href,
  description,
  Icon,
  onMouseEnter,
  onMouseLeave,
  size
}: SlideProps) => {
  const isSmall = size === 'small';
  const isLarge = size === 'large';
  const {
    transitions,
    breakpoints
  } = useTheme();
  const transitionOptions = {
    duration: transitions.duration.complex
  };
  return <Card sx={{
    borderRadius: 2,
    bgcolor: 'rgba(255, 255, 255, 0.80)',
    borderWidth: 0,
    p: 0,
    transition: transitions.create(['width', 'min-width'], transitionOptions),
    [breakpoints.only('xs')]: {
      width: 234,
      minWidth: 234
    },
    [breakpoints.up(1200)]: {
      width: isSmall ? 170 : isLarge ? 225 : 180,
      minWidth: isSmall ? 170 : isLarge ? 225 : 180
    },
    [breakpoints.up(1300)]: {
      width: isSmall ? 172 : isLarge ? 234 : 193,
      minWidth: isSmall ? 172 : isLarge ? 234 : 193
    }
  }} data-sentry-element="Card" data-sentry-component="Slide" data-sentry-source-file="UmbrellaIntroCarousel.tsx">
      <CardActionArea
    // @ts-expect-error It's a button base, so it should accept an href, not sure why it's not working
    // @see https://mui.com/material-ui/api/card-action-area/#inheritance
    href={href} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-sentry-element="CardActionArea" data-sentry-source-file="UmbrellaIntroCarousel.tsx">
        <Stack sx={{
        alignItems: 'start',
        justifyContent: 'start',
        transition: transitions.create(['padding-left', 'padding-bottom'], transitionOptions),
        pt: [2, 1, 2, 3],
        pb: [2, 1, 1, isLarge ? 2.5 : 3],
        pl: [4.5, 2, isLarge ? 2 : 0, isLarge ? 4.5 : 0]
      }} data-sentry-element="Stack" data-sentry-source-file="UmbrellaIntroCarousel.tsx">
          <Stack data-cy="icon-container" sx={{
          alignItems: 'center',
          transition: transitions.create(['min-width'], transitionOptions),
          minWidth: isLarge ? '0%' : '100%'
        }} data-sentry-element="Stack" data-sentry-source-file="UmbrellaIntroCarousel.tsx">
            <Icon width={34} height={34} data-sentry-element="Icon" data-sentry-source-file="UmbrellaIntroCarousel.tsx" />
          </Stack>
          <Typography variant="bodySmallPrimary" align="center" sx={{
          mt: 1.5,
          color: 'text.primary',
          transition: transitions.create(['min-width'], transitionOptions),
          minWidth: isLarge ? '0%' : '100%'
        }} data-sentry-element="Typography" data-sentry-source-file="UmbrellaIntroCarousel.tsx">
            {title}
          </Typography>
          <Collapse in={isLarge} timeout={transitions.duration.complex} data-sentry-element="Collapse" data-sentry-source-file="UmbrellaIntroCarousel.tsx">
            <Typography variant="bodySmall" sx={{
            mt: 0.5,
            pr: 2,
            color: 'text.primary',
            opacity: isLarge ? 1 : 0,
            transition: transitions.create(['opacity'], {
              duration: transitions.duration.short,
              delay: transitions.duration.standard
            }),
            minHeight: '3lh'
          }} data-sentry-element="Typography" data-sentry-source-file="UmbrellaIntroCarousel.tsx">
              {description}
            </Typography>
          </Collapse>
        </Stack>
      </CardActionArea>
    </Card>;
};
export const UmbrellaIntroCarousel = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const isMdBreakpoint = useIsUnderBreakpoint('lg');
  if (isMdBreakpoint) {
    return <Box sx={{
      px: 4,
      pt: 3
    }}>
        <BaseCarousel slidesCount={6} slidesToShow={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 5,
        xl: 5
      }} customSettings={{
        prevArrow: <UmbrellaCarouselArrow variant="prev" />,
        nextArrow: <UmbrellaCarouselArrow variant="next" />
      }}>
          {slidesData.map((slide, index) => <Box key={slide.title}>
              <Stack sx={{
            alignItems: 'center',
            px: 1
          }}>
                <Slide {...slide} size="large" onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)} />
              </Stack>
            </Box>)}
        </BaseCarousel>
      </Box>;
  }
  return <Stack direction="row" sx={{
    justifyContent: 'center',
    alignItems: 'center',
    gap: hoveredIndex === null ? '11px' : '15px',
    transition: ({
      transitions
    }) => transitions.create(['gap']),
    height: 152
  }} data-sentry-element="Stack" data-sentry-component="UmbrellaIntroCarousel" data-sentry-source-file="UmbrellaIntroCarousel.tsx">
      {slidesData.map((slide, index) => <Slide key={slide.title} {...slide} size={hoveredIndex === null ? 'default' : hoveredIndex === index ? 'large' : 'small'} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)} />)}
    </Stack>;
};