'use client';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, Link, Stack, IconButton, Box } from '@mui/material';
import { UmbrellaButton } from '@/app/(index)/_components/_components/UmbrellaButton';
import { ReactComponent as Logo } from '../_assets/logo.svg';
import { MenuContainer } from './MenuContainer';
export const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Close the menu on navigation
  const pathName = usePathname();
  useEffect(() => {
    setIsOpen(false);
  }, [pathName]);
  return <>
      <Stack direction="row" sx={{
      justifyContent: 'space-between',
      pl: 1,
      pr: 1.5,
      py: 1,
      width: '100%',
      position: 'fixed',
      zIndex: 'snackbar',
      top: 0,
      background: 'rgba(255, 255, 255, 0.92)',
      backdropFilter: 'blur(5px)'
    }} data-sentry-element="Stack" data-sentry-source-file="HeaderMobile.tsx">
        <Link href="/" title="Go to Quotebeam homepage" sx={{
        height: 32,
        display: 'flex'
      }} data-sentry-element="Link" data-sentry-source-file="HeaderMobile.tsx">
          <Logo height="100%" data-sentry-element="Logo" data-sentry-source-file="HeaderMobile.tsx" />
        </Link>

        <IconButton onClick={() => setIsOpen(prevState => !prevState)} sx={{
        p: 0.5,
        color: 'text.primary'
      }} data-sentry-element="IconButton" data-sentry-source-file="HeaderMobile.tsx">
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Stack>
      <Drawer hideBackdrop={true} anchor="top" open={isOpen} onClose={() => setIsOpen(false)} PaperProps={{
      sx: {
        top: 48,
        border: '0 none',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.92)',
        backdropFilter: 'blur(5px)'
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="HeaderMobile.tsx">
        <Box sx={{
        py: 1.5,
        px: 1.5
      }} data-sentry-element="Box" data-sentry-source-file="HeaderMobile.tsx">
          <Stack direction="row" sx={{
          justifyContent: 'center',
          gap: 2.5,
          mb: 1
        }} data-sentry-element="Stack" data-sentry-source-file="HeaderMobile.tsx">
            <UmbrellaButton href="/get-a-demo" variant="outlined" size="small" sx={{
            px: 2
          }} data-sentry-element="UmbrellaButton" data-sentry-source-file="HeaderMobile.tsx">
              Talk to Sales
            </UmbrellaButton>
            <UmbrellaButton href="/get-a-demo" variant="contained" size="small" sx={{
            px: 3
          }} data-sentry-element="UmbrellaButton" data-sentry-source-file="HeaderMobile.tsx">
              Get a Demo
            </UmbrellaButton>
          </Stack>
          <Stack direction="column" sx={{
          alignItems: 'start'
        }} data-sentry-element="Stack" data-sentry-source-file="HeaderMobile.tsx">
            <MenuContainer data-sentry-element="MenuContainer" data-sentry-source-file="HeaderMobile.tsx" />
          </Stack>
        </Box>
      </Drawer>
    </>;
};